<template>
  <common-rules :title="$t('rules.specified_commercial_transactions.title')" :rules-type="rulesType" />
</template>

<script>
import CommonRules from "@/components/CommonRules.vue";
import { RULES_TYPE } from "@/const/Enum";

/**
 * 特定商取引法に基づく表示
 */
export default {
  components: {
    CommonRules
  },
  data() {
    return {
      rulesType: RULES_TYPE.SPECIFIED_COMMERCIAL_TRANSACTIONS
    };
  },
  methods: {}
};
</script>

<style scoped></style>
