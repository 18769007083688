import * as StoreUtils from "@/store/StoreUtils";

const DEFAULT_TIMEOUT = 60000;

/**
 * リポジトリベース
 */
export default ($axios) => ({
  // GETリクエスト
  get: (url, params) => {
    StoreUtils.showLoading();
    return $axios
      .get(url, {
        params: params,
        timeout: DEFAULT_TIMEOUT
      })
      .finally(() => {
        StoreUtils.hideLoading();
      });
  }
});
