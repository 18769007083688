const state = {
  isLoading: false
};
const mutations = {
  showLoading(state, data) {
    state.isLoading = data;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
