<template>
  <header>
    <div id="mainImageMin"><img src="@/assets/images/ttfc_logo_n.png" width="640" height="420" alt="" /></div>
    <h2>{{ title }}</h2>
  </header>
</template>

<script>
/**
 * ヘッダーコンポーネント
 */
export default {
  components: {},
  props: {
    title: { default: "" }
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped>
h2 {
  text-align: center;
}
#mainImageMin img {
  width: 10%;
  margin: auto auto;
  height: auto;
  display: block;
}
</style>
