import common from "./common.json";
import rules from "./rules.json";
import button from "./button.json";
import error from "./error.json";
export default {
  ...common,
  ...rules,
  ...button,
  ...error
};
