import Repository from "@/repositories/Repository";

const SHARE = "share";
const RULES = "rules";
const NEW_MEMBER_INFO = "new_member_info";
const QUESTION_AND_ANSWER = "question_and_answer";

/**
 * 共有サイト API
 */
export default ($axios) => ({
  /**
   * 共有情報取得（記事情報）
   * @param {*} payload
   */
  share(payload) {
    return Repository($axios).get(SHARE, payload);
  },
  /**
   * 規約系情報取得
   * @param {*} payload
   */
  rules(payload) {
    return Repository($axios).get(RULES, payload);
  },
  /**
   * 新規会員情報取得
   * @param {*} payload
   */
  newMemberInfo(payload) {
    return Repository($axios).get(NEW_MEMBER_INFO, payload);
  },
  /**
   * BNIDログアウト
   */
  faq() {
    return Repository($axios).get(QUESTION_AND_ANSWER);
  }
});
