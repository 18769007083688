import Vue from "vue";
import VueI18n from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueHead from "vue-head";
import VueLodash from "vue-lodash";
import _ from "lodash";
import axios from "@/plugins/axios";
import CommonUtils from "@/mixins/CommonUtils";
import messages from "@/plugins/messages.js";
import Toasted from "vue-toasted";
import "@/assets/css/index.css";

Vue.prototype.$version = "0.0.1";
Vue.config.productionTip = false;

Vue.use(VueHead);
Vue.use(VueLodash, { lodash: _ });
Vue.use(axios);
Vue.use(VueI18n);
const options = {
  position: "top-center",
  duration: 3000,
  fullWidth: false
};
Vue.use(Toasted, options);
Vue.mixin(CommonUtils);

const i18n = new VueI18n({
  locale: "ja",
  fallbackLocale: "ja",
  messages
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
