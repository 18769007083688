import Vue from "vue";
import VueRouter from "vue-router";
import VueBodyClass from "vue-body-class";

// components
import SystemRequirements from "@/views/SystemRequirements.vue";
import QuestionAndAnswer from "@/views/QuestionAndAnswer.vue";
import TermsService from "@/views/TermsService.vue";
import CommentGuideline from "@/views/CommentGuideline.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import PrivacyPolicyFireTV from "@/views/PrivacyPolicyFireTV.vue";
import SpecifiedCommercialTransactions from "@/views/SpecifiedCommercialTransactions.vue";
import SpecifiedCommercialTransactionsFireTV from "@/views/SpecifiedCommercialTransactionsFireTV.vue";
import Takeover from "@/views/Takeover.vue";
import PushSettings from "@/views/PushSettings.vue";
import NewMemberInfo from "@/views/NewMemberInfo.vue";
import QuestionAndAnswerFireTV from "@/views/QuestionAndAnswerFireTV.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/system_requirements",
    name: "SystemRequirements",
    component: SystemRequirements
  },
  {
    path: "/question_and_answer",
    name: "QuestionAndAnswer",
    component: QuestionAndAnswer
  },
  {
    path: "/question_and_answer_firetv",
    name: "QuestionAndAnswerFireTV",
    component: QuestionAndAnswerFireTV
  },
  {
    path: "/terms_service",
    name: "TermsService",
    component: TermsService
  },
  {
    path: "/comment_guideline",
    name: "CommentGuideline",
    component: CommentGuideline
  },
  {
    path: "/privacy_policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  },
  {
    path: "/privacy_policy_firetv",
    name: "PrivacyPolicyFireTV",
    component: PrivacyPolicyFireTV
  },
  {
    path: "/specified_commercial_transactions",
    name: "SpecifiedCommercialTransactions",
    component: SpecifiedCommercialTransactions
  },
  {
    path: "/specified_commercial_transactions_firetv",
    name: "SpecifiedCommercialTransactionFireTVs",
    component: SpecifiedCommercialTransactionsFireTV
  },
  {
    path: "/takeover",
    name: "Takeover",
    component: Takeover
  },
  {
    path: "/push_settings",
    name: "PushSettings",
    component: PushSettings
  },
  {
    path: "/new_member_info",
    name: "NewMemberInfo",
    component: NewMemberInfo
  }
];

const router = new VueRouter({
  mode: "history",
  // ルートで使う場合、vue.config.jsのpublicPathを"/"にする場合は、以下も"/"にすること。
  base: process.env.BASE_URL,
  routes,
  // 画面遷移時のスクロール位置調整
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next);
});

export default router;
