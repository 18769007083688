<template>
  <common-rules :title="$t('rules.push_settings.title')" :rules-type="rulesType" />
</template>

<script>
import CommonRules from "@/components/CommonRules.vue";
import { RULES_TYPE } from "@/const/Enum";

/**
 * PUSH通知設定説明
 */
export default {
  components: {
    CommonRules
  },
  data() {
    return {
      rulesType: RULES_TYPE.PUSH_SETTINGS
    };
  },
  methods: {}
};
</script>

<style scoped></style>
