// 規約種別
export const RULES_TYPE = {
  SYSTEM_REQUIREMENTS: 0, // 動作環境
  QUESTION_AND_ANSWER: 1, // Q&A
  TERMS_SERVICE: 2, // 利用規約
  COMMENT_GUIDELINE: 3, // コメントガイドライン
  PRIVACY_POLICY: 4, // 個人情報保護方針
  SPECIFIED_COMMERCIAL_TRANSACTIONS: 5, // 特定商取引法に基づく表示
  TAKEOVER: 6, // 会員引き継ぎ説明
  PUSH_SETTINGS: 7 // PUSH通知設定説明
};
