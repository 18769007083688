<template>
  <div class="modal-mask" v-if="$store.state.loading.isLoading">
    <div class="loading">
      <vue-loading type="spin" color="#FFF" :size="{ width: '80px', height: '80px' }"></vue-loading>
    </div>
  </div>
</template>

<script>
import { VueLoading } from "vue-loading-template";

export default {
  components: {
    VueLoading
  }
};
</script>

<style scoped>
.modal-mask {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
