<template>
  <div class="wrapper">
    <Header :title="title" v-if="!isApp"></Header>
    <div class="text-area" v-html="compiledMarkdown(text)"></div>
    <Footer v-if="!hideFooter()"></Footer>
  </div>
</template>

<script>
import Const from "@/const/Const";
import { RULES_TYPE } from "@/const/Enum";
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import { marked } from "marked";
import * as DOMPurify from "dompurify";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const Repository = RepositoryFactory.get("support");

/**
 * 規約系共通コンポーネント
 */
export default {
  components: {
    Header,
    Footer
  },
  props: {
    title: { default: "" },
    rulesType: { type: Number, default: 0 }
  },
  created() {
    const payload = {
      rulesType: this.rulesType
    };
    Repository(this.$axios)
      .rules(payload)
      .then((response) => {
        this.text = response.data.text;
      })
      .catch((error) => {
        if (this._.isNil(error.response) || error.code === "ERR_NETWORK") {
          console.debug("通信エラー");
          // 通信エラーダイアログ表示
          this.$toasted.error(Const.NETWORK_ERROR_MESSAGE);
          return;
        }
        let message = Const.SERVER_ERROR_MESSAGE;
        try {
          message = JSON.parse(error.response.data).message;
        } catch (e) {
          // NOP
        }
        if (this._.isNil(message)) {
          message = Const.SERVER_ERROR_MESSAGE;
        }
        this.$toasted.error(message);
      });
  },
  mounted() {
    // クエリ文字列指定ある時はPCと判定
    const { mode } = this.$route.query;
    if (this._.isNil(mode)) {
      this.isApp = true;
      return;
    }
    this.isApp = false;
  },
  data() {
    return {
      isApp: true,
      text: ""
    };
  },
  methods: {
    hideFooter() {
      // コメントのガイドライン、会員引き継ぎ説明の時は、フッター不要
      return this.rulesType === RULES_TYPE.COMMENT_GUIDELINE || this.rulesType === RULES_TYPE.TAKEOVER;
    },
    compiledMarkdown(text) {
      // return DOMPurify.sanitize(marked(text));
      const renderer = new marked.Renderer();
      const linkRenderer = renderer.link;
      renderer.link = (href, title, text) => {
        // ここでのページ内リンク #開始かどうか
        const isAnchorLink = href.startsWith(`#`);
        const html = linkRenderer.call(renderer, href, title, text);
        if (isAnchorLink) {
          return html;
        }
        return html.replace(/^<a /, `<a target="_blank" rel="noreferrer noopener nofollow" `);
      };
      const value = marked(text, { renderer });
      return DOMPurify.sanitize(value, { ADD_ATTR: ["target"] });
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 640px) {
  .wrapper {
    width: 800px;
    margin: 0 auto;
    padding-bottom: 120px;
    overflow-x: hidden;
  }
}

.text-area {
  margin: 10px;
}

:deep(a) {
  color: #0099ff;
}
</style>
