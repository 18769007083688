const state = {
  version: null
};
const mutations = {
  setVersion(state, data) {
    state.version = data;
  }
};
const actions = {
  setVersion(context, data) {
    context.commit("setVersion", data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
