<template>
  <div>
    <collapse-transition>
      <div v-show="isOpen">
        <slot></slot>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { CollapseTransition } from "@ivanv/vue-collapse-transition";

export default {
  name: "Accordion",
  components: {
    CollapseTransition
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    clazz: { default: "" }
  }
};
</script>

<style scoped></style>
