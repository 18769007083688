/**
 * 各コンポーネント共通で使用するメソッド定義
 */
export default {
  methods: {
    /**
     * 子コンポーネントから値変更イベントを受け取り、値を更新
     */
    async updateValue(event, providerRef, item, target) {
      // MEMO:バリデーション検証を手動発火させる。
      await this.$refs[providerRef].validate(event);
      this.$set(item, target, event);
    }
  }
};
