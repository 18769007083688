<template>
  <common-rules :title="$t('rules.privacy_policy.title')" :rules-type="rulesType" />
</template>

<script>
import CommonRules from "@/components/CommonRules.vue";
import { RULES_TYPE } from "@/const/Enum";

/**
 * 個人情報保護方針
 */
export default {
  components: {
    CommonRules
  },
  data() {
    return {
      rulesType: RULES_TYPE.PRIVACY_POLICY
    };
  },
  methods: {}
};
</script>

<style scoped></style>
