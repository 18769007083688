import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import app from "./modules/app";
import loading from "./modules/loading";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    loading
  },
  strict: true,
  plugins: [
    createPersistedState({
      key: "TTFC_SUPPORT",
      paths: ["app.version"],
      storage: window.sessionStorage
    })
  ]
});
export default store;
