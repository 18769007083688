<template>
  <footer>
    <address>&copy; {{ $t("common.pageAuthor") }}</address>
  </footer>
</template>

<script>
/**
 * フッターコンポーネント
 */
export default {
  methods: {}
};
</script>

<style scoped>
footer {
  margin-top: 5em;
}
address {
  text-align: center;
  font-size: 0.8em;
}
</style>
