<template>
  <div id="app">
    <main>
      <router-view />
    </main>
    <loading />
  </div>
</template>

<script>
import Loading from "@/components/Loading";

export default {
  name: "App",
  components: {
    Loading
  }
};
</script>

<style>
body {
  background-color: #1c1d1c;
  color: #fff !important;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic",
    sans-serif;
  font-size: 14px;
  margin: 10px;
  /* コピー禁止 */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

blockquote {
  margin: 0rem !important;
  background: #343634 !important;
  padding: 0.1rem 0.6rem !important;
  color: #7c7d7c;
  border-radius: 2px;
}
</style>
