import axios from "axios";
import _ from "lodash";
import * as StoreUtils from "@/store/StoreUtils";
const parser = require("ua-parser-js");

/**
 * axiosインスタンス生成 + 設定
 */
const AXIOS_DEFAULT_TIMEOUT = 10000;
const debug = process.env.VUE_APP_ENV !== "production";

const baseDomain = process.env.VUE_APP_API_BASE_URL ? process.env.VUE_APP_API_BASE_URL : "http://localhost:8080";
const baseURL = `${baseDomain}/api/support/`;
const HEADER_PLATFORM_UNKNOWN = "99";

const apiAxios = axios.create({
  baseURL,
  timeout: AXIOS_DEFAULT_TIMEOUT
});

const toastOptions = {
  position: "top-center",
  duration: 2000,
  fullWidth: false,
  type: "error"
};

// 共通
apiAxios.interceptors.request.use(
  function (config) {
    // リクエストHTTPヘッダー設定
    config.headers["App-Version"] = process.env.VUE_APP_VERSION;
    config.headers["Platform"] = HEADER_PLATFORM_UNKNOWN;
    const result = parser();
    const deviceName = `${result.os.name}(${result.os.version}),${result.browser.name}(${result.browser.version})`;
    config.headers["Device-Name"] = deviceName;
    config.headers["Language"] = process.env.VUE_APP_COUNTRY_CODE;
    // TODO: BASIC認証はいずれ消す
    config.headers.Authorization = "Basic QzNkRWZnRmZCMmU6QWRta2RlN3M3ZDNrZA==";

    if (debug) {
      console.log("--------------------------------------------------");
      console.log(`[Request] ${config.method.toUpperCase()} ${config.baseURL}${config.url}`);
      console.log(config.headers);
      console.log("config:");
      console.dir(config);
      if (config.data) {
        console.log("[Request Body]" + JSON.stringify(config.data));
      }
    }
    return config;
  },
  function (error) {
    // インターネット未接続時など
    if (debug) {
      console.log(`onRequestError: ${error}`);
      console.log("--------------------------------------------------");
    }
    return Promise.reject(error);
  }
);

// 共通
apiAxios.interceptors.response.use(
  function (response) {
    if (debug) {
      console.log(`[Response] ${response.status}`);
      console.log(response.headers);
      if (response.data) {
        console.log(`[Response Body] ${JSON.stringify(response.data)}`);
      }
      console.log("--------------------------------------------------");
    }
    return response;
  },
  function (error) {
    let response = error.response;
    if (_.isNil(error.response) || error.code === "ERR_NETWORK") {
      throw error;
    }

    if (debug) {
      console.log(`[Response] ${response.status}`);
      console.log(response.headers);
      if (response.data) {
        console.log(`[Response Body] ${JSON.stringify(response.data)}`);
      }
      console.log("--------------------------------------------------");
    }
    throw error;
  }
);

const Axios = {
  install(Vue) {
    Vue.prototype.$axios = apiAxios;
  }
};
export default Axios;
