<template>
  <div class="wrapper">
    <div class="header">
      {{ title }}
    </div>
    <div v-for="(item, index) in form.items" :key="`main-${index}`">
      <div class="main-title" @click="toggleOpen(item)">
        <div class="rect"></div>
        <a :id="item.anchor">{{ item.title }}</a>
        <span class="icon">
          <chevron-up-icon v-if="item.open" size="1.5x" class="custom-class"></chevron-up-icon>
          <chevron-down-icon v-else size="1.5x" class="custom-class"></chevron-down-icon>
        </span>
      </div>
      <accordion class="accordion-box" :isOpen="item.open">
        <div v-for="(subItem, subIndex) in item.items" :key="`sub-${subIndex}`">
          <div class="sub-title" @click="toggleOpen(subItem)">
            <span class="text">
              {{ subItem.title }}
            </span>
            <div class="icon">
              <chevron-up-icon v-if="subItem.open" size="1.5x" class="custom-class"></chevron-up-icon>
              <chevron-down-icon v-else size="1.5x" class="custom-class"></chevron-down-icon>
            </div>
          </div>
          <div :id="subItem.anchor"></div>
          <accordion :isOpen="subItem.open">
            <div class="text-area" v-html="compiledMarkdown(subItem.text)"></div>
          </accordion>
        </div>
      </accordion>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Const from "@/const/Const";
import { RULES_TYPE } from "@/const/Enum";
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import Accordion from "@/components/Accordion.vue";
import { ChevronDownIcon, ChevronUpIcon } from "vue-feather-icons";
import { marked } from "marked";
import * as DOMPurify from "dompurify";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const Repository = RepositoryFactory.get("support");

/**
 * Q&A FireTV
 */
export default {
  components: {
    Footer,
    Accordion,
    ChevronDownIcon,
    ChevronUpIcon
  },
  props: {
    title: { default: "東映特撮ファンクラブ - Q&A" },
    rulesType: { type: Number, default: RULES_TYPE.QUESTION_AND_ANSWER }
  },
  created() {
    Repository(this.$axios)
      .faq()
      .then((response) => {
        this.form = { ...response.data };
        this.openByAnchor(this.form.items);
      })
      .catch((error) => {
        if (this._.isNil(error.response) || error.code === "ERR_NETWORK") {
          console.debug("通信エラー");
          // 通信エラーダイアログ表示
          this.$toasted.error(Const.NETWORK_ERROR_MESSAGE);
          return;
        }
        let message = Const.SERVER_ERROR_MESSAGE;
        try {
          message = JSON.parse(error.response.data).message;
        } catch (e) {
          // NOP
        }
        if (this._.isNil(message)) {
          message = Const.SERVER_ERROR_MESSAGE;
        }
        this.$toasted.error(message);
      });
  },
  mounted() {
    const { hash } = this.$route;
    this.anchor = hash.substring(1);
  },
  data() {
    return {
      anchor: "",
      form: {
        items: [
          {
            title: "",
            anchor: "",
            open: false,
            items: [
              {
                title: "",
                open: false,
                text: ""
              }
            ]
          }
        ]
      }
    };
  },
  methods: {
    toggleOpen(item) {
      this.$set(item, "open", !item.open);
    },
    /**
     * URLでアンカー指定合った場合、対象アンカーのアコーディオンを開いておく
     */
    openByAnchor(items) {
      if (this._.isNil(this.anchor)) {
        return;
      }
      items.forEach((item) => {
        if (item.anchor === this.anchor) {
          this.$set(item, "open", true);
        }
      });
    },
    compiledMarkdown(text) {
      return DOMPurify.sanitize(marked(text));
    }
  }
};
</script>

<style scoped lang="scss">
.accordion-box {
  margin-bottom: 0.5rem;
}
.wrapper {
  background-color: #333333;
  color: #ffffff;
  padding: 30px 30px 0px 30px;
  font-size: 1.5vw;
  margin: -10px;
  min-height: 100vh;
  height: 100%;
}

.header {
  text-align: center;
  font-size: 3vw;
  padding-bottom: 20px;
  padding: 10px;
}
.rect {
  display: flex;
  background-color: #69b3b6;
  color: #69b3b6;
  width: 14px;
  height: 14px;
  border-radius: 2px;
}
.main-title {
  align-items: center;
  font-weight: bold;
  position: relative;
  padding: 5px 0px 0 0px;
  cursor: pointer;
  display: flex;

  a {
    color: #fff;
    padding-left: 1em;
    margin-right: 0.6rem;
    display: flex;
  }
}

.sub-title {
  font-weight: bold;
  color: #47a3a7 !important;
  cursor: pointer;
  margin: 10px 0 0;
  display: flex;
}
.text {
  text-align: left;
  margin-right: 0.3rem;
}
.icon {
  display: flex;
  text-align: right;
  margin-right: 5px;
  color: #a2a2a2;
}
:deep .text-area {
  padding-bottom: 1rem;
  word-break: break-word;
  blockquote {
    margin: 0rem !important;
    background: #343634 !important;
    padding: 0.1rem 0.6rem !important;
    color: #7c7d7c;
    border-radius: 2px;
  }
}

:deep .text-area {
  padding-bottom: 1rem;
  word-break: break-word;
}
:deep(a) {
  color: #0099ff;
}
</style>
