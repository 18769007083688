<template>
  <div class="wrapper">
    <div class="img-area">
      <img :src="imageUrl" class="my-img" />
    </div>
    <div :class="classText">{{ text }}</div>
  </div>
</template>

<script>
import Const from "@/const/Const";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const Repository = RepositoryFactory.get("support");

export const OS_TYPE = {
  IOS: 0,
  ANDROID: 1,
  FIRE_TV: 2,
  FIRE_TABLET: 3,
  PC: 4,
  UNKNOWN: 5
};
/**
 * 新規会員登録情報表示
 */
export default {
  components: {},
  data() {
    return {
      osType: 0,
      text: "",
      imageUrl: ""
    };
  },
  mounted() {
    // body 背景色変更
    let body = document.getElementsByTagName("body")[0];
    body.style.backgroundColor = "#2e2f2e";

    // クエリ文字列指定ある時はPCと判定
    const { osType } = this.$route.query;
    if (this._.isNil(osType)) {
      this.platform = 0;
      return;
    }
    this.osType = osType;
    this.load();
  },
  computed: {
    classText() {
      const isFireTV = this.osType == OS_TYPE.FIRE_TV;
      return isFireTV ? "text-area-fireTv" : "text-area";
    }
  },
  methods: {
    load() {
      const payload = {
        osType: this.osType
      };
      Repository(this.$axios)
        .newMemberInfo(payload)
        .then((response) => {
          this.text = response.data.text;
          this.imageUrl = response.data.imageUrl;
        })
        .catch((error) => {
          if (this._.isNil(error.response) || error.code === "ERR_NETWORK") {
            console.debug("通信エラー");
            // 通信エラーダイアログ表示
            this.$toasted.error(Const.NETWORK_ERROR_MESSAGE);
            return;
          }
          let message = Const.SERVER_ERROR_MESSAGE;
          try {
            message = JSON.parse(error.response.data).message;
          } catch (e) {
            // NOP
          }
          if (this._.isNil(message)) {
            message = Const.SERVER_ERROR_MESSAGE;
          }
          this.$toasted.error(message);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.img-area {
  text-align: center;
  margin-top: 20px;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
}
.text-area {
  font-size: 0.8em;
  margin: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  &-fireTv {
    margin: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 0.6em;
  }
}
.my-img {
  width: 100% !important;
  /* aspect-ratio: 16 / 9; */
  object-fit: contain;
  max-height: 250px;
  background-color: #2e2f2e;
  /* @media screen and (max-width: 480px) {
    max-height: 250px;
  } */
}
</style>
