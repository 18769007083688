import Store from "@/store/";
/**
 * Vuex系のUtils
 */

/**
 * ローディング表示
 */
export function showLoading() {
  Store.commit("loading/showLoading", true);
}

/**
 * ローディングを非表示
 */
export function hideLoading() {
  Store.commit("loading/showLoading", false);
}
