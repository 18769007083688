import SupportRepository from "./SupportRepository";
/**
 * リポジトリファクトリー
 */
const repositories = {
  support: SupportRepository
};

export const RepositoryFactory = {
  get: (name) => repositories[name]
};
