<template>
  <div class="wrapper">
    <Header :title="title" v-if="!isApp"></Header>
    <div v-for="(item, index) in form.items" :key="`main-${index}`">
      <div :id="item.anchor"></div>
      <div class="main-title" @click="toggleOpen(item)">
        <div class="rect"></div>
        <a class="mr-1" :id="item.anchor">{{ item.title }}</a>
        <span class="icon">
          <chevron-up-icon v-if="item.open" size="1.5x" class="custom-class"></chevron-up-icon>
          <chevron-down-icon v-else size="1.5x" class="custom-class"></chevron-down-icon>
        </span>
      </div>
      <accordion :isOpen="item.open" class="accordion-box">
        <div v-for="(subItem, subIndex) in item.items" :key="`sub-${subIndex}`">
          <div class="sub-title" @click="toggleOpen(subItem)">
            <span class="text">
              {{ subItem.title }}
            </span>
            <span class="icon">
              <chevron-up-icon v-if="subItem.open" size="1.5x" class="custom-class"></chevron-up-icon>
              <chevron-down-icon v-else size="1.5x" class="custom-class"></chevron-down-icon>
            </span>
          </div>
          <div :id="subItem.anchor"></div>
          <accordion :isOpen="subItem.open">
            <div class="text-area" v-html="compiledMarkdown(subItem.text)" @click="handleClick"></div>
          </accordion>
        </div>
      </accordion>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Const from "@/const/Const";
import { RULES_TYPE } from "@/const/Enum";
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import Accordion from "@/components/Accordion.vue";
import { ChevronDownIcon, ChevronUpIcon } from "vue-feather-icons";
import { marked } from "marked";
import * as DOMPurify from "dompurify";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const Repository = RepositoryFactory.get("support");

/**
 * Q&A
 */
export default {
  components: {
    Header,
    Footer,
    Accordion,
    ChevronDownIcon,
    ChevronUpIcon
  },
  props: {
    title: { default: "東映特撮ファンクラブ - Q&A" },
    rulesType: { type: Number, default: RULES_TYPE.QUESTION_AND_ANSWER }
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    if (to.hash !== from.hash) {
      // ページ内リンク対応
      this.anchor = to.hash.substring(1);
      this.hash = to.hash;
      this.openByAnchor(this.form.items);
      this.scroll();
    }
    next();
  },
  created() {
    Repository(this.$axios)
      .faq()
      .then((response) => {
        this.form = { ...response.data };
        this.openByAnchor(this.form.items);
        this.scroll();
      })
      .catch((error) => {
        if (this._.isNil(error.response) || error.code === "ERR_NETWORK") {
          console.debug("通信エラー");
          // 通信エラーダイアログ表示
          this.$toasted.error(Const.NETWORK_ERROR_MESSAGE);
          return;
        }
        let message = Const.SERVER_ERROR_MESSAGE;
        try {
          message = JSON.parse(error.response.data).message;
        } catch (e) {
          // NOP
        }
        if (this._.isNil(message)) {
          message = Const.SERVER_ERROR_MESSAGE;
        }
        this.$toasted.error(message);
      });
  },
  mounted() {
    const { hash } = this.$route;
    this.anchor = hash.substring(1);
    // クエリ文字列指定ある時はPCと判定
    const { mode } = this.$route.query;
    if (this._.isNil(mode)) {
      this.isApp = true;
      return;
    }
    this.isApp = false;
  },
  data() {
    return {
      isApp: true,
      hash: this.$route.hash,
      anchor: "",
      form: {
        items: [
          {
            title: "",
            anchor: "",
            open: false,
            items: [
              {
                title: "",
                open: false,
                text: ""
              }
            ]
          }
        ]
      }
    };
  },
  methods: {
    handleClick(event) {
      // クリックされた要素が a タグであるか確認
      if (event.target.tagName === "A") {
        // ページ内リンク押下時、hashが同じだった場合の対応
        const targetHref = event.target.getAttribute("href");
        // ページ内リンクか #開始かどうか
        const isAnchorLink = targetHref.startsWith(`#`);
        if (isAnchorLink) {
          const isSameHash = this.hash == targetHref;
          if (isSameHash) {
            // ページ内リンク時はイベント無効化
            event.preventDefault();
            this.openByAnchor(this.form.items);
            this.scroll();
          }
        }
      }
    },
    toggleOpen(item) {
      this.$set(item, "open", !item.open);
    },
    /**
     * URLでアンカー指定合った場合、対象アンカーのアコーディオンを開いておく
     */
    openByAnchor(items) {
      if (this._.isNil(this.anchor)) {
        return;
      }
      items.forEach((item) => {
        if (item.anchor === this.anchor) {
          this.$set(item, "open", true);
        }
        // 小項目
        if (!this._.isNil(item.items)) {
          const childAnker = item?.items.some((i) => i.anchor === this.anchor);
          if (childAnker) {
            this.$set(item, "open", true);
          }
          this.openByAnchor(item.items);
        }
      });
    },
    compiledMarkdown(text) {
      // return DOMPurify.sanitize(marked.parse(text, { break: true }));
      const renderer = new marked.Renderer();
      const linkRenderer = renderer.link;
      renderer.link = (href, title, text) => {
        // ここでのページ内リンク #開始かどうか
        const isAnchorLink = href.startsWith(`#`);
        const html = linkRenderer.call(renderer, href, title, text);
        if (isAnchorLink) {
          return html;
        }
        return html.replace(/^<a /, `<a target="_blank" rel="noreferrer noopener nofollow" `);
      };
      const value = marked(text, { renderer });
      return DOMPurify.sanitize(value, { ADD_ATTR: ["target"] });
    },
    scroll() {
      this.$nextTick(() => {
        // DOM更新後にスクロールさせる
        if (!this.hash) {
          return;
        }
        const targetId = this.hash.replace("#", "");
        const target = document.getElementById(targetId);
        if (target) {
          // アコーディオンのduration考慮
          setTimeout(() => {
            window.scrollTo({
              top: target.offsetTop,
              behavior: "smooth"
            });
          }, 300);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.accordion-box {
  margin-bottom: 0.5rem;
}

.rect {
  display: flex;
  background-color: #69b3b6;
  color: #69b3b6;
  min-width: 14px;
  min-height: 14px;
  border-radius: 2px;
}

.main-title {
  font-size: 1rem;

  @media screen and (min-width: 1024px) {
    font-size: 1.2rem;
  }

  align-items: center;
  font-weight: bold;
  position: relative;
  padding: 5px 0px 0 0px;
  cursor: pointer;
  display: flex;

  a {
    color: #fff;
    padding-left: 1em;
    margin-right: 0.6rem;
    display: flex;
  }
}

.sub-title {
  font-size: 0.9rem;

  @media screen and (min-width: 1024px) {
    font-size: 1rem;
  }

  font-weight: bold;
  color: #47a3a7 !important;
  cursor: pointer;
  margin: 10px 0 0;
  display: flex;
}

@media screen and (min-width: 640px) {
  .wrapper {
    width: 800px;
    margin: 0 auto;
    padding-bottom: 120px;
    overflow-x: hidden;
  }
}

.text {
  text-align: left;
  margin-right: 0.3rem;
}

.icon {
  display: flex;
  text-align: right;
  margin-right: 5px;
  color: #a2a2a2;
}

:deep .text-area {
  font-size: 0.8rem;
  padding-bottom: 1rem;
  word-break: break-word;
  padding: 0.2rem 0;
}

:deep(a) {
  color: #0099ff;
}
</style>
